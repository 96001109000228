import React, { useEffect, useState } from 'react'

import { createServerHelper } from '@/server/context'
import { dehydrate } from '@tanstack/react-query'
import { addDays, addMonths, endOfDay, lastDayOfMonth, startOfMonth } from 'date-fns'

import { GetServerSidePropsContext } from 'next'

/* Hooks */
import { useGetTranslations } from '@/i18n/index.tsx'

/* Context */
import { useApplicationContext } from '@/context/ApplicationContext.tsx'

import { NOTIFICATION_TYPE } from '@/constants/constants'

/* Utils */
import { findNextAvailableBookingDay, formatDate } from '@/utils/calendarUtils.ts'
import { getDefaultVenueSettings } from '@/utils/stateUtils'
import { trpc } from '@/utils/trpc.ts'

import { useDefer } from '@/hooks/useDefer.ts'

import VenueInformationComponent from '@/components/AdminDashboard/VenueInformationComponent/VenueInformationComponent.tsx'
import BookingPageComponent from '@/components/BookingPageComponent/BookingPageComponent.tsx'

/* Components */
import CustomerLayout from '@/layouts/CustomerLayout.tsx'

import { ExtendedEventsT, ExtendedVenueSettingsT } from '@/types/globalTypes'

const BookingPage = ({
    venue,
    initialQueryKey,
    serverEvents,
}: {
    venue: ExtendedVenueSettingsT
    serverEvents: ExtendedEventsT[]
    initialQueryKey: {
        start_date: string
        end_date: string
        locale: string
        venue_id: string
    }
}) => {
    const { setToastNotification } = useApplicationContext()

    const { TOAST_NOTIFICATIONS_TEXT } = useGetTranslations()

    const {
        working_hours,
        reservation_lead_time,
        max_calendar_days,
        days_of_operation,
        name,
        logo_url,
        description,
        map_url,
        address,
        phone_number,
        email,
        website,
        instagram_link,
        linkedin_link,
        tiktok_link,
        youtube_link,
        facebook_link,
        allow_reservations,
    } = venue

    const [dateToday, setDateToday] = useState(new Date())

    const [selectedDate, setSelectedDate] = useState(() => {
        // If the current day is active and the current time is at least before the reservation_lead_time aka if the lead time is 1 hour before the starting time,
        // return the current day as the selected date. Otherwise, find the next available day.
        return findNextAvailableBookingDay(dateToday, working_hours, reservation_lead_time)
    })

    const dayOfWeek = new Date(selectedDate).getDay()

    const { starting_hours } = working_hours[dayOfWeek] || {
        starting_hours: '00:00',
        ending_hours: '00:00',
        is_active: false,
    }

    useEffect(() => {
        // Convert starting_hours to a Date object, assuming it's for today
        const [startingHours, startingMinutes] = starting_hours.split(':').map(Number)
        const startingTimeToday = new Date()
        startingTimeToday.setHours(startingHours, startingMinutes, 0, 0)

        // Subtract 1 hour to get the reevaluation time
        const reevaluationTime = new Date(startingTimeToday.getTime() - 60 * 60 * 1000)

        // If reevaluationTime is in the future, set up a timeout to call reevaluateSelectedDate
        if (reevaluationTime > new Date()) {
            const delta = reevaluationTime.valueOf() - Date.now()

            const timeout = setTimeout(() => {
                setSelectedDate(
                    findNextAvailableBookingDay(dateToday, working_hours, reservation_lead_time)
                )
            }, delta)
            return () => clearTimeout(timeout) // Cleanup the timeout if the component is unmounted
        }
    }, [dateToday, reservation_lead_time, starting_hours, working_hours])

    const [activeMonth, setActiveMonth] = useState({
        startOfMonth: formatDate(startOfMonth(selectedDate)),
        endOfMonth: formatDate(lastDayOfMonth(addMonths(selectedDate, 1))),
    })

    const [minDate] = useState(dateToday)
    const [maxDate] = useState(addDays(dateToday, max_calendar_days))

    const { data, failureCount } = trpc.events.getEventsByDateRange.useQuery(
        {
            ...initialQueryKey,
            start_date: activeMonth.startOfMonth,
            end_date: activeMonth.endOfMonth,
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 30000,
            initialData: serverEvents,
            retry: () => {
                setToastNotification({
                    message: TOAST_NOTIFICATIONS_TEXT.GENERIC_ERROR_MESSAGE,
                    type: NOTIFICATION_TYPE.ERROR,
                })
                return failureCount === 1
            },
        }
    )

    useDefer(endOfDay(dateToday), () => {
        setDateToday(new Date())
    })

    // Remove other loading states and use initialLoadComplete for the layout
    return (
        <CustomerLayout initializePageLoad={false} isAuthorized={true}>
            <div className="animate-fade">
                <BookingPageComponent
                    dateToday={dateToday}
                    selectedDate={selectedDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    allow_reservations={allow_reservations}
                    days_of_operation={days_of_operation}
                    events={(data as ExtendedEventsT[]) || serverEvents}
                    setActiveMonth={setActiveMonth}
                    setSelectedDate={setSelectedDate}
                />

                <VenueInformationComponent
                    address={address}
                    description={description}
                    email={email}
                    facebook_link={facebook_link}
                    instagram_link={instagram_link}
                    linkedin_link={linkedin_link}
                    logo_url={logo_url}
                    map_url={map_url}
                    name={name}
                    phone_number={phone_number}
                    tiktok_link={tiktok_link}
                    website={website}
                    working_hours={working_hours}
                    youtube_link={youtube_link}
                />
            </div>
        </CustomerLayout>
    )
}

export async function getServerSideProps(opts: GetServerSidePropsContext) {
    try {
        const { req, res, locale } = opts
        const dateToday = new Date()
        const initialStartOfMonth = formatDate(startOfMonth(dateToday))
        const initialEndOfMonth = formatDate(lastDayOfMonth(addMonths(dateToday, 1)))

        const helpers = await createServerHelper({ req, res } as GetServerSidePropsContext)

        const venue_settings = await helpers.venueSettings.getVenueInformation.fetch()

        const queryKey = {
            start_date: initialStartOfMonth,
            end_date: initialEndOfMonth,
            locale,
            venue_id: venue_settings.id,
        }

        const serverEvents = await helpers.events.getEventsByDateRange.fetch(queryKey)
        const dehydratedState = dehydrate(helpers.queryClient)
        return {
            props: {
                venue: venue_settings,
                serverEvents,
                initialQueryKey: queryKey,
                trpcState: dehydratedState,
            },
        }
    } catch (error) {
        console.error('Error in getServerSideProps:', error)
        return {
            props: {
                venue: getDefaultVenueSettings(),
                initialQueryKey: null,
                serverEvents: [],
                trpcState: {},
            },
        }
    }
}

export default BookingPage
