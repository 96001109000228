import React from 'react'
import Calendar from 'react-calendar'
import {
    TileClassNameFunc,
    TileContentFunc,
    TileDisabledFunc,
    Value,
} from 'react-calendar/dist/cjs/shared/types'

import {
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
} from '@heroicons/react/24/outline'

import { useApplicationContext } from '@/context/ApplicationContext.tsx'

import { LANGUAGES } from '@/constants/constants'

interface CalendarComponentProps {
    selectedDate: Value
    setSelectedDate: (value: Value) => void
    titleContent?: TileContentFunc
    disableDate?: TileDisabledFunc
    minDate?: Date
    maxDate?: Date
    tileClassName?: TileClassNameFunc
    selectRange?: boolean
    containerClassName?: string
    className?: string
    formatDay?: (locale: string, date: Date) => string
    onActiveStartDateChange?: (props: any) => void
}

const CalendarComponent = ({
    selectedDate,
    setSelectedDate,
    titleContent,
    disableDate,
    minDate,
    maxDate,
    tileClassName,
    selectRange,
    containerClassName = 'justify-center m-auto max-sm:w-auto xl:w-3/6',
    className = 'm-auto shadow-md animate-fade animate-duration-250 animate-once bg-base-100',
    formatDay,
    onActiveStartDateChange,
}: CalendarComponentProps) => {
    const { applicationState } = useApplicationContext()

    return (
        <div className={containerClassName}>
            <Calendar
                className={className}
                onChange={setSelectedDate}
                value={selectedDate}
                tileClassName={tileClassName}
                tileContent={titleContent}
                tileDisabled={disableDate}
                minDate={minDate}
                maxDate={maxDate}
                locale={
                    applicationState.selectedLanguage
                        ? applicationState.selectedLanguage
                        : LANGUAGES.EN
                }
                prev2Label={<ChevronDoubleLeftIcon className="w-6 h-6 m-auto" />}
                prevLabel={<ChevronLeftIcon className="w-6 h-6 m-auto" />}
                nextLabel={<ChevronRightIcon className="w-6 h-6 m-auto" />}
                next2Label={<ChevronDoubleRightIcon className="w-6 h-6 m-auto" />}
                selectRange={selectRange}
                formatDay={formatDay}
                onActiveStartDateChange={onActiveStartDateChange}
            />
        </div>
    )
}

export default CalendarComponent
